import { Route, Routes } from 'react-router-dom'
import Home from './pages/home'
// import Mint from './pages/mint'
// import Swap from './pages/swap'
// import Liquidity from './pages/liquidity'
// import Migration from './pages/liquidity/migration'
// import ManageLiquidity from './pages/liquidity/manageLiquidity'
// import ManageLiquidityV3 from './pages/liquidity/manageLiquidityV3'
// import Lock from './pages/lock'
// import Vote from './pages/vote'
// import Rewards from './pages/rewards'
// import Options from './pages/options'
// import WhiteList from './pages/whiteList'
// import AddBribe from './pages/whiteList/bribeModal'
// import AddGauge from './pages/whiteList/gaugeModal'
// import PageNotFound from './pages/404NotFound'
import { RefreshContextProvider } from './context/RefreshContext'
import { ToastContainer, Zoom } from 'react-toastify'
import { useVideoAutoplay } from './helpers/useAutoPlay'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { VeTHEsContextProvider } from './context/veTHEsConetext'
import { BaseAssetsConetextProvider } from './context/BaseAssetsConetext'
import { RouteAssetsConetextProvider } from './context/RouteAssetsConetext'
import { PairsContextProvider } from './context/PairsContext'
import { FusionsContextProvider } from './context/FusionsContext'
import ApplicationUpdater from './state/application/updater'
import MultiCallV3Updater from './state/multicall/v3/updater'
// import Header from './components/common/Header'
import { useLocation } from 'react-router-dom'
// import SideNav from './components/common/SideNav'
// import Footer from './pages/retro/footer'

import Big from 'big.js'
// import { VoteOptimizer } from 'pages/voteOptimizer'
// import { BoostCalculator } from 'pages/boostCalculator'
// import { Dashboard } from 'pages/dashboard'
// import { WidoWidgetPage } from 'pages/wido-widget'
import Transaction from 'components/common/Transaction'
// import { VeTokenManager } from 'pages/veTokenManager'
// import { SingleDeposit } from 'pages/singleDeposit'

Big.NE = -20
Big.DP = 20
Big.PE = 40

const Updaters = () => {
  return (
    <>
      <ApplicationUpdater />
      <MultiCallV3Updater />
    </>
  )
}

const App = () => {
  useVideoAutoplay()
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  return (
    <div className='main'>
      <RefreshContextProvider>
        <Updaters />
        <BaseAssetsConetextProvider>
          <RouteAssetsConetextProvider>
            <PairsContextProvider>
              <VeTHEsContextProvider>
                <FusionsContextProvider>
                  <div className={`${!isHomePage ? 'flex flex-col' : ''}`}>
                    {/* {!isHomePage && <Header />} */}
                    <div className={`${!isHomePage ? 'flex' : ''}`}>
                      {/* {!isHomePage && <SideNav />} */}
                      <div
                        className={`${!isHomePage ? 'relative w-full flex flex-col min-h-appBox overflow-hidden' : ''}`}
                      >
                        {/* {!isHomePage && (
                          <img
                            src='/images/common/main-bg.svg'
                            className='fixed w-full h-mainBg -bottom-1/4 left-0 -z-20 hidden xl:block'
                            alt='Page Background'
                          />
                        )} */}
                        <Transaction />
                        <Routes>
                          <Route path='/' element={<Home />} exact />
                          {/* <Route path='/theNFT' element={<Mint />} exact /> */}
                          {/* <Route path='/swap' element={<Swap />} exact />
                          <Route path='/lock' element={<Lock />} exact />
                          <Route path='/vote' element={<Vote />} exact />
                          <Route path='/vote/:veId' element={<Vote />} exact />
                          <Route path='/options' element={<Options />} exact />
                          <Route path='/rewards' element={<Rewards />} exact />
                          <Route path='/liquidity' element={<Liquidity />} exact /> */}
                          {/* <Route path='/liquidity/liquidity' element={<Liquidity />} exact /> */}
                          {/* <Route path='/liquidity/migration' element={<Migration />} exact /> */}
                          {/* <Route path='/liquidity/manage' element={<ManageLiquidity />} exact />
                          <Route path='/liquidity/manageV3' element={<ManageLiquidityV3 />} exact />
                          <Route path='/liquidity/manage/:address' element={<ManageLiquidity />} exact />
                          <Route path='/bribes' element={<WhiteList />} exact />
                          <Route path='/bribes/add' element={<AddBribe />} exact />
                          {process.env.REACT_APP_VERCEL_ENV === 'preview' && (
                            <Route path='/whitelist/gauge' element={<AddGauge />} exact />
                          )} */}
                          {/* <Route path='/satin-migration' element={<SatinMigrationPage />} exact /> */}
                          {/* <Route path='/vote-optimizer' element={<VoteOptimizer />} exact />
                          <Route path='/boost-calculator' element={<BoostCalculator />} exact />
                          <Route path='/dashboard' element={<Dashboard />} exact />
                          <Route path='/zap' element={<WidoWidgetPage />} exact />
                          <Route path='/ve-manager' element={<VeTokenManager />} />
                          <Route path='/single-deposit' element={<SingleDeposit />} /> */}

                          {/* <Route path='/whitelist/gauge' element={<AddGauge />} exact /> */}
                          {/* {/* <Route path='/referral' element={<Referral />} exact /> */}
                          {/* <Route path='/404' element={<PageNotFound />} exact />
                          <Route path='*' element={<PageNotFound />} exact /> */}
                        </Routes>
                        {/* {!isHomePage && (
                          <div className='flex w-full sm:hidden'>
                            <Footer />
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </FusionsContextProvider>
              </VeTHEsContextProvider>
            </PairsContextProvider>
          </RouteAssetsConetextProvider>
        </BaseAssetsConetextProvider>
      </RefreshContextProvider>
      <ToastContainer
        className='notify-class'
        position='top-right'
        theme='dark'
        closeOnClick={false}
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
      />
    </div>
  )
}

export default App
