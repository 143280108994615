import React, { useState, useEffect } from 'react'
import './header.scss'

const Header = () => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    function handleScroll() {
      const offset = window.scrollY
      if (offset > 50) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header className={scrolled ? 'header scrolled' : 'header'}>
      <a href='#' className='logo'></a>
      <nav className='desktop-menu'>
        <div className='desktop-menu-container'>
          <a href='https://app.retro.finance' className='desktop-button'>
            <img src='/images/retro/buttons/rocket-launch.svg' alt='' className='button-image' />
            Launch App
          </a>
        </div>
      </nav>
      <div className='mobile-menu-container'>
        <div className='relative w-full'>
          <a
            href='https://app.retro.finance'
            className={`bg-white w-full min-w-[150px] max-w-[252px] xl:max-w-full xl:w-[252px] flex-shrink-0 py-2 px-4 xl:py-4 xl:px-10 rounded-md items-center gap-x-1 xl:gap-x-2.5 font-montSerrat font-semibold text-black text-sm md:text-sm relative z-20  xl:text-base leading-7`}
          >
            Launch App
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
